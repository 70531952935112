'use client';
import { useEffect } from 'react';

import { hasKwankoCookieApprovedAtom } from '@tracking/Atoms/HasKwankoCookieApproved.atom';
import { useAtom } from 'jotai';
import Script from 'next/script';

export const KwankoScript = () => {
  const KwankoIABID = 702;
  const [hasKwankoCookie, setHasKwankoCookie] = useAtom(hasKwankoCookieApprovedAtom);

  useEffect(() => {
    const callback = (tcData: any, success: any) => {
      if (!tcData) return;

      if (success) {
        if (tcData?.vendor?.consents[KwankoIABID]) {
          setHasKwankoCookie(true);
        }
      }
    };

    window?.__tcfapi?.('addEventListener', 2, callback, [KwankoIABID]);

    return () => {
      window?.__tcfapi?.('removeEventListener', 2, callback);
    };
  }, []);

  return (
    <>
      {hasKwankoCookie && (
        <Script
          type="text/javascript"
          src="https://zms.heycar.com/js/na/na/u/2817bc5c56.js"
        ></Script>
      )}
    </>
  );
};

KwankoScript.displayName = 'KwankoScript';
