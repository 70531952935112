'use client';

import Script from 'next/script';

// implemented according to the docs sent by the french agency
// https://prebidaio.reworldmediafactory.com/doc-site-evol/en/heycar.com_fr/%2F9259737%2Fheycar.fr-web?habillage=0&oneView=1&cmp=nocmp&prebidUtils=1&safeFrame=0

export const ExternalAdScripts = () => {
  return (
    <>
      <Script
        id="device-detection-script"
        type="text/javascript"
        src="/ads_scripts/device_detection.js"
        async
      />
      <Script
        id="ads-fr-script"
        type="text/javascript"
        src="https://prebid.reworldmediafactory.com/heycar.com_fr/script.min.js"
        async
      />
      <Script
        id="pbutils-script"
        type="text/javascript"
        src="https://prebid.reworldmediafactory.com/pbutils/prebid_utils.min.js"
        async
      />
      <Script
        id="gam-placement-script"
        type="text/javascript"
        src="/ads_scripts/gam_placement.js"
        async
      />
    </>
  );
};

ExternalAdScripts.displayName = 'ExternalAdScripts';
