'use client';

import { useEffect, useState } from 'react';

import { useAtomValue } from 'jotai';
import dynamic from 'next/dynamic';

import { useSearchParams } from 'next-source/navigation';

import { Locale } from '@core/Entities/Locale/Locale.entity';

import { activeAccModalAtom } from '../../Atoms/AccModal.atom';

import { AccountFormDefaultMessages } from './AccountForm.messages';

type Props = {
  strings: AccountFormDefaultMessages;
  locale: Locale;
};

const AccConfirmDetailsForm = dynamic(
  () => import('./AccConfirmDetailsForm').then(mod => mod.AccConfirmDetailsForm),
  { ssr: false },
);

const AccLoginForm = dynamic(() => import('./AccLoginForm').then(mod => mod.AccLoginForm), {
  ssr: false,
});

const AccSignupForm = dynamic(() => import('./AccSignupForm').then(mod => mod.AccSignupForm), {
  ssr: false,
});

const AccEmailPendingForm = dynamic(
  () => import('./AccEmailPendingForm').then(mod => mod.AccEmailPendingForm),
  { ssr: false },
);

const AccForgotPasswordForm = dynamic(
  () => import('./AccForgotPasswordForm').then(mod => mod.AccForgotPasswordForm),
  { ssr: false },
);

const AccForgotPasswordSuccessForm = dynamic(
  () => import('./AccForgotPasswordSuccessForm').then(mod => mod.AccForgotPasswordSuccessForm),
  { ssr: false },
);

const AccReverifyForm = dynamic(
  () => import('./AccReverifyForm').then(mod => mod.AccReverifyForm),
  { ssr: false },
);

export const AccountFormModals = ({ strings, locale }: Props) => {
  const activeModalId = useAtomValue(activeAccModalAtom);
  const searchParams = useSearchParams();

  const [openedModals, setOpenedModals] = useState<string[]>([]);

  useEffect(() => {
    if (searchParams.get('login') === 'show') {
      setOpenedModals(prev => [...prev, 'login']);
    } else if (activeModalId) {
      setOpenedModals(prev => [...prev, activeModalId]);
    }
  }, [activeModalId, searchParams]);

  const hasBeenOpened = (modalId: string) => openedModals.includes(modalId);

  return (
    <>
      {hasBeenOpened('login') && <AccLoginForm strings={strings} />}
      {hasBeenOpened('confirmDetails') && <AccConfirmDetailsForm strings={strings} />}
      {hasBeenOpened('signup') && <AccSignupForm locale={locale} strings={strings} />}
      {hasBeenOpened('emailPending') && <AccEmailPendingForm strings={strings} />}
      {hasBeenOpened('forgotPassword') && <AccForgotPasswordForm strings={strings} />}
      {hasBeenOpened('forgotPasswordSuccess') && <AccForgotPasswordSuccessForm strings={strings} />}
      {hasBeenOpened('reverify') && <AccReverifyForm strings={strings} />}
    </>
  );
};
