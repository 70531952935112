import React from 'react';
import styles from './Toast.module.css';

type ToastVariant = 'primary' | 'secondary' | 'tertiary' | 'success' | 'warning' | 'error' | 'dark';

export type ToastProps = {
  id?: string;
  children?: React.ReactNode;
  variant?: ToastVariant;
  icon?: React.ReactNode;
  disabled?: boolean;
  inactive?: boolean;
};

export const Toast = ({
  children,
  variant = 'primary',
  icon,
  disabled = false,
  inactive,
}: ToastProps) => {
  return (
    <div className={styles.wrapper} data-is-open={!!children} data-is-inactive={inactive}>
      <div className={styles.inner}>
        <div className={styles.toast} data-variant={variant} data-disabled={disabled}>
          {icon && <div className={styles.icon}>{icon}</div>}
          <span className={styles.message}>{children} &nbsp;</span>
        </div>
      </div>
    </div>
  );
};
